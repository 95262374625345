import axios from 'axios';

axios.defaults.withCredentials = true;

export default {
  doLogin(body) {
    return axios.post(`${process.env.VUE_APP_AUTH_API_ENDPOINT}`, body);
  },
  async getInformation() {
    try {
      return await axios.get(`${process.env.VUE_APP_AUTH_API_ENDPOINT}`);
    } catch (e) {
      return e.response;
    }
  },
};
