<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <v-col cols="12" class="text-center" v-if="userinfo">
        <v-row>
          <v-col cols="12" class="mb-10">
            <span class="roboto fs-title semi-bold primary-text-color">
              Seleccione uma opção
            </span>
          </v-col>
        </v-row>
        <v-row v-for="app in userinfo" :key="JSON.stringify(app)" justify="center">
          <v-col cols="3" class="pa-1">
            <v-card
              color="white"
              flat
              class="py-1 px-6 elevation-default"
              rounded
            >
              <v-card-text class="px-0">
                <v-row>
                  <v-col cols="12" class="d-flex">
                    <span
                      v-if="app.type === 'home'"
                      class="my-auto roboto fs-secondary-header semi-bold primary-text-color">
                      Área de Empresa
                    </span>
                    <span
                      v-else-if="app.type === 'exams'"
                      class="my-auto roboto fs-secondary-header semi-bold primary-text-color">
                      Realização de Exames
                    </span>
                    <span
                      v-else-if="app.type === 'validation'"
                      class="my-auto roboto fs-secondary-header semi-bold primary-text-color">
                      Validação de Exames
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn depressed class="my-auto fs-label" color="primary" outlined small
                           @click="redirect(app.redirect, app.type, app.newWindow)">
                      Selecionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Location',
  data() {
    return {
      userinfo: JSON.parse(localStorage.getItem('redirects')),
    };
  },
  props: {
    snackbar: Object,
  },
  methods: {
    redirect(url, type, newWindow) {
      if (type === 'validation' && localStorage.getItem('redirected_chat') === '0') {
        window.open(newWindow, '_blank');
        localStorage.setItem('redirected_chat', '1');
        window.location = url;
      } else if (type === 'validation' && localStorage.getItem('redirected_chat') === '1') {
        window.location = newWindow;
      } else {
        window.location = url;
      }
    },
  },
};
</script>
