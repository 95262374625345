import authService from '../services/Auth';

export default async (to, from, next) => {
  const res = await authService.getInformation();
  if (res.status === 401) {
    next();
  } else {
    next('/selection');
  }
};
