import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#016AD5',
        secondary: '#DCEAF3',
        tertiary: '#ffffff',
        background: '#f3f6f8',
        background2: '#dceaf3',
        background3: '#eef4f9',
        modalbackground: '#dceaf3',
        success: '#00CC97',
        error: '#F13751',
      },
    },
  },
});
