import authService from '../services/Auth';

export default async (to, from, next) => {
  const res = await authService.getInformation();
  if (res.status !== 401) {
    if (res.status === 200) {
      next();
    } else {
      next(false);
    }
  } else {
    window.location = process.env.VUE_APP_CARDIO_ON_REDIRECT_URL;
    next(false);
  }
};
