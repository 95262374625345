<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <v-col cols="12">
        <v-form @submit.prevent="doLogin">
          <v-row justify="center">
            <v-col cols="2">
              <v-img src="../assets/img/logo.png" width="75px" class="mx-auto"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center" class="focus-elem">
            <v-col cols="2" class="py-0">
              <span class="primary-text-color semi-bold source-sans-pro fs-label">
                Nome de utilizador
              </span>
              <v-text-field
                v-model="login_form.username"
                label=" "
                color="tertiary"
                dense
                solo
                flat
                hide-details
                class="source-sans-pro primary-text-color fs-field my-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="focus-elem">
            <v-col cols="2" class="py-0">
              <span class="primary-text-color semi-bold source-sans-pro fs-label">
                Palavra-passe
              </span>
              <v-text-field
                type="password"
                v-model="login_form.password"
                label=" "
                color="tertiary"
                dense
                solo
                flat
                hide-details
                class="source-sans-pro primary-text-color fs-field my-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="2" class="py-2">
              <v-btn
                small
                depressed
                :disabled="login_form.disabled"
                color="primary"
                type="submit"
                class="roboto fs-secondary"
                block
                >Entrar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authService from "../services/Auth";

export default {
  name: "Login",
  data() {
    return {
      login_form: {
        username: null,
        password: null,
        disabled: false
      }
    };
  },
  props: {
    snackbar: Object
  },
  created() {
    this.snackbar.show = false;
  },
  methods: {
    doLogin() {
      this.login_form.disabled = true;

      if (!this.login_form.username) {
        this.snackbar.show = false;
        this.$nextTick(() => {
          this.snackbar.show = true;
          this.snackbar.text = "O Nome de utilizador é um campo obrigatório!";
          this.snackbar.color = "error";
        });
        this.login_form.disabled = false;
        return;
      }
      if (!this.login_form.password) {
        this.snackbar.show = false;
        this.$nextTick(() => {
          this.snackbar.show = true;
          this.snackbar.text = "A Palavra-passe é um campo obrigatório!";
          this.snackbar.color = "error";
        });
        this.login_form.disabled = false;
        return;
      }

      authService
        .doLogin({
          username: this.login_form.username,
          password: this.login_form.password
        })
        .then(loginResponse => {
          localStorage.setItem("redirected_chat", "0");
          if (loginResponse.status === 200) {
            if (loginResponse.data.length === 0) {
              this.snackbar.show = false;
              this.$nextTick(() => {
                this.snackbar.show = true;
                this.snackbar.text =
                  "Ocorreu um erro a tentar fazer login! Por favor contacte helpdesk@cardio-on.com.";
                this.snackbar.color = "error";
              });
            } else if (loginResponse.data.length === 1) {
              localStorage.setItem("redirects", JSON.stringify(loginResponse.data));
              const { redirect, type, newWindow } = loginResponse.data[0];
              if (type === "validation") {
                localStorage.setItem("redirected_chat", "1");
                window.open(newWindow, "_blank");
              }
              window.location = redirect;
            } else {
              localStorage.setItem("redirects", JSON.stringify(loginResponse.data));
              this.$router.push("/selection");
            }
          } else {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro a tentar fazer login! Por favor contacte helpdesk@cardio-on.com.";
              this.snackbar.color = "error";
            });
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Esta combinação de nome de utilizador/palavra-passe não existe!";
              this.snackbar.color = "error";
            });
          } else {
            this.snackbar.show = false;
            this.$nextTick(() => {
              this.snackbar.show = true;
              this.snackbar.text =
                "Ocorreu um erro a tentar fazer login! Por favor contacte helpdesk@cardio-on.com.";
              this.snackbar.color = "error";
            });
          }
        });

      setTimeout(() => (this.login_form.disabled = false), 2000);
    }
  }
};
</script>
