import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

import './css/main.css';
import './css/fields.css';
import './css/fonts.css';
import './css/colors.css';
import './css/tabs.css';
import './css/animations.css';
import './css/tables.css';
import './css/menu.css';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
