<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-main>
      <MessageBox :props="snackbar" />
      <Navbar />
      <router-view :snackbar="snackbar" />
    </v-main>
  </v-app>
</template>

<script>
import MessageBox from "@/components/MessageBox.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
    MessageBox
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: null,
        color: null,
        timeout: 5000
      }
    };
  }
};
</script>
