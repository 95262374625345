<template>
  <v-container fill-height fluid>
    <v-row align="center" class="text-center">
      <v-col cols="12">
        <span class="error-header-text-color fs-error-title source-sans-pro semi-bold"
              v-if="activeError">{{ activeError.title }}</span>
      </v-col>
      <v-col cols="12">
        <span class="error-description-text-color fs-header source-sans-pro"
              v-if="activeError">{{ activeError.description }}</span>
      </v-col>
      <v-col cols="12">
        <v-btn depressed color="primary" small class="source-sans-pro fs-label"
               @click="redirectToHome"
               style="text-decoration: none; color: white !important;">Voltar à página inicial
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      error: {
        id: null,
        list: {
          404: {
            title: '404',
            description: 'O recurso que procura não foi encontrado.',
          },
          500: {
            title: '500',
            description: 'Ocorreu um erro a recolher a informação do servidor.',
          },
          default: {
            title: 'Oops',
            description: 'Ocorreu um erro a recolher a informação do servidor.',
          },
        },
      },
    };
  },
  computed: {
    activeError() {
      return this.error.list[this.error.id]
        ? this.error.list[this.error.id]
        : this.error.list.default;
    },
  },
  mounted() {
    this.error.id = this.$route.params.id ? this.$route.params.id : 404;
  },
  methods: {
    redirectToHome() {
      window.location = process.env.VUE_APP_CARDIO_ON_REDIRECT_URL;
    },
  },
};
</script>
