import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import AppSelection from '../views/AppSelection.vue';
import Error from '../views/Error.vue';

import LoginGuard from '../guards/Login';
import SelectionGuard from '../guards/Selection';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: LoginGuard,
  },
  {
    path: '/selection',
    name: 'AppSelection',
    component: AppSelection,
    beforeEnter: SelectionGuard,
  },
  {
    path: '*',
    name: 'NotFound',
    component: Error,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
