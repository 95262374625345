<template>
  <v-app-bar
    color="white"
    class="elevation-default"
    dense
    fixed
  >
    <v-img
      src="../assets/img/logoh.png"
      max-height="30"
      max-width="170"
      contain
    ></v-img>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {};
  },
};
</script>
